//import host from "../settings";
export const navPages = {
  "home": {
    key: 0,
    trans: 'up',
    back: 'down'
  },
  "pointman": {
    key: 1,
    trans: 'left',
    back: 'right'
  },
  "cardman": {
    key: 2,
    trans: 'right',
    back: 'left'
  },
  "cardinfo": {
    key: 3,
    trans: 'right',
    back: 'left'
  },
  "newcard": {
    key: 4,
    trans: 'right',
    back: 'left'
  },
  "cardhistory": {
    key: 5,
    trans: 'right',
    back: 'left'
  }
}

export function convertUnicode(input) {
    return input.replace(/\\u(\w\w\w\w)/g,function(a,b) {
      var charcode = parseInt(b,16);
      return String.fromCharCode(charcode);
    });
}

export function leadz(n, l=4) {
  return ("0".repeat(l) + n).substr(-l,l);
}

/*** Text Mező lekérdezése.
 * @protectnumeric: input class
 ***/

export function inputHandler(event) {
  const target = event.target;
  if ( target.classList.contains("protectnumeric") ) {
    if (!((event.which >= 48 && event.which <= 57) || (event.which >= 96 && event.which <= 105) || event.which === 8 || event.which === 9 || event.which === 46 || event.which === 37 || event.which === 39) )
    //if ( (event.which !== 8 && event.which !== 0 && event.which < 48 ) || (event.which > 57) )
    {
      event.preventDefault();
    }
  }
  if(target.value.length > target.maxLength) target.value = target.value.slice(0, target.maxLength);
  return;
}

export function checkObj(postobj) {
  for (var k in postobj ) {
    //console.log(k, postobj[k])
    if ( postobj[k] === null || postobj[k] === undefined || postobj[k] === '' ) {
      return false;
    } 
  }
  return true;
}

export function addThsndSep(nStr) {
  nStr += '';
  const x = nStr.split('.');
  let x1 = x[0];
  const x2 = x.length > 1 ? '.' + x[1] : '';
  var rgx = /(\d+)(\d{3})/;
  while (rgx.test(x1)) {
      //x1 = x1.replace(rgx, '$1' + " " + '$2');
      x1 = x1.replace(rgx, `$1 $2`);
  }
  return x1 + x2;
}