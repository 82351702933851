import React from 'react';
import '../css/PicButton.css';
import * as GF from './GlobalFunctions'

const Picbutton = (props) => {
    let { btIcon, btClassName, btLabel, onFormSubmit, className, btID } = props;
    let iconIndStyle = '';
    let iconIndStyleL = '';
    if (btIcon) iconIndStyle = ' hasIcon';
    if (!btLabel) iconIndStyleL = ' noLabel';
    return (
        <div className={`btnContainer ${className}`}>
            <div 
                    className={btClassName ? btClassName + ' btn' + iconIndStyle + iconIndStyleL : 'btn' + iconIndStyle + iconIndStyleL }>{ btIcon ? <span className='btnIcon'>{ GF.convertUnicode(btIcon) }</span> : ''}
                    <span className="btLabel">{ btLabel }</span>
                    <div className='clicksrf' onClick={onFormSubmit} id={ btID }></div>
            </div>
        </div>
    );
}

Picbutton.defaultProps = {
    className: ''
}

export default Picbutton;
