import React from 'react'
import host from '../settings'

const References = (props) => {
  
    return (
        props.references.map((ref, i) => {
            return (
                <a key={i} href={ref.image_link} target='_blank' rel="noopener noreferrer" title={ref.image_title}><img src={host + ref.image_url} alt={ref.image_title}/></a>
            )
        })
    )

}

References.defaultProps = {
    references: [{ image_link: '', image_title: '', image_url: ''}]
}

export default References