import React, { Component } from 'react'
import Map from './Map'
import Alert from './Alert'
import References from './References'
import * as Popmessages from './Popmessages'
import '../css/App.css'
import host from '../settings'
import logoWhite from '../images/cflogo_white.svg'

const ar_settings  =  "/?rest_route=/wp/v2/cfsettings"
const ar_contacts  =  "/?rest_route=/wp/v2/cfcontacts&per_page=100"
const ar_countries =  "/?rest_route=/wp/v2/cfcountries&per_page=100"
const ar_references = "/?rest_route=/wp/v2/cfimages&per_page=100"
const ar_articles =   "/?rest_route=/wl/v1/posts/"

const loadheader = <header><figure><img className='mainlogo' src={logoWhite} alt='Connceting Finland'/></figure><h1>Connecting Finland</h1><span className='motto'>Loading...</span></header>

class App extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isFetching: false,
      popup: false,
      message: '',
      poptype: 'alert',
      settings: null,
      dataCollection: [],
      articles: []
    }
  }

  togglepopup = () => {
    this.setState( { popup: !this.state.popup } )
  }

  fetcher = async (apihost, apiroute, target, type='application/json', output='json', subtarget=0) => {
    try {
        const response = await fetch( apihost + apiroute, { credentials: 'include', method: 'GET', headers: {'Content-Type': type} })
        let data = output === 'json' ? await response.json() :  await response.text()
        if ( response.status === 200 && data.length > 0 ) {
          if (output !== 'json') {
            let s = data.substr(1).slice(0, -1);
            let c = s.replace(/\\n/g, '')
            let v = c.replace(/\\/g, '')
            let at = this.state.articles
            at[subtarget] = v
            this.setState({ articles: at })
          } else {
            this.setState({[target]: data })
          }
        } else {
          throw new Error(Popmessages.somathinghappened)
        }
    } catch (error) {
        this.setState({ popup: true, poptype: 'error', message: error.message }) 
    }  
  }
  
  WBfetcher = async (apihost, apiroute, target) => {
    try {
        const response = await fetch(apihost + apiroute, { method: 'GET' })
        let data = await response.json()
        if ( response.status === 200 && data.length > 0 ) {
          localStorage.setItem(target, JSON.stringify(data))
          let dc = this.state.dataCollection
          dc.push(data)
          this.setState({ dataCollection: dc })
        } else {
          if (localStorage.getItem(target)) {
            data = JSON.parse(localStorage.getItem(target))
            let dc = this.state.dataCollection
            dc.push(data)
            this.setState({ dataCollection: dc })
          } else {
            throw new Error(Popmessages.somathinghappened)
          }
        }
    } catch (error) {
        this.setState({ popup: true, poptype: 'error', message: error.message }) 
    }  
  }
  
  prepareWBFetchApiURL = (apiurl) => {
    let au = apiurl
    au = au.replace('$1', this.state.settings[0].countries)
    au = au.replace('$2', this.state.settings[0].start_time)
    au = au.replace('$3', this.state.settings[0].end_time)
    au = au.replace('$4', this.state.settings[0].max)
    return au
  }

  getAllDataFetched = () => {
    return Promise.all([
        this.fetcher(host, ar_settings, 'settings'),
        this.fetcher(host, ar_contacts, 'contacts'),
        this.fetcher(host, ar_countries, 'countries'),
        this.fetcher(host, ar_references, 'references')
      ])
  }
  
  getAllDataWBData = () => {
    return Promise.all([
      this.state.settings[0].wbd.split(';').map((wbelem, i) => { 
        return this.WBfetcher('', this.prepareWBFetchApiURL(wbelem), 'wbd'+i)
      })
    ])
  }
  
  getAllArticles = () => {
    return Promise.all([
      this.state.settings[0].artids.split(';').map((article, i) => { 
        return this.fetcher(host, ar_articles+article, `articles`, 'text/html', 'html', i)
      })
    ])
  }

  createMarkup(html) {
    return { __html: html };
  }

  componentDidMount = () => {
    this.setState({ isFetching: true, poptype: 'loading', popup: true })
    this.getAllDataFetched().then(() => {
      this.getAllArticles().then(() => {
        this.getAllDataWBData().then(() => {
          this.setState({ popup: false, isFetching: false })
        })
      })
    })
  }

  render = () => {
    let motto
    if (this.state.settings) {
      const mottoarr = this.state.settings[0].mottos.split(';')
      motto = mottoarr[Math.floor(Math.random()*mottoarr.length)]
    }

    return (
      <div className={`App ${ this.state.popup ? 'loading' : 'loaded'}`} >
        { this.state.popup ? <Alert poptype={this.state.poptype} message={this.state.message} header={loadheader} closePopup={this.togglepopup.bind(this)}/> : null }
        <div className='page'>
          <header>
            <figure>
              <img className='mainlogo' src={logoWhite} alt='Connceting Finland'/>
            </figure>
            <h1>Connecting Finland</h1>
            { motto ? <span className='motto'>{motto}</span> : null }
          </header>
          { this.state.isFetching === false ? <Map settings={this.state.settings} countries={this.state.countries} contacts={this.state.contacts} data={this.state.dataCollection} /> : null } 
          { this.state.articles.length > 0 ? <section id="content1" className="white" dangerouslySetInnerHTML={this.createMarkup(this.state.articles[0])} /> : null }
          { this.state.articles.length > 0 ? <section id="content2" className="white" dangerouslySetInnerHTML={this.createMarkup(this.state.articles[1])} /> : null }
          <section id='references' className='darkblue'>
            <h1>References</h1>
            { this.state.isFetching === false ? <div className='references'><References references={this.state.references}/></div> : null }
          </section>
          <footer>
            { this.state.articles.length > 0 ? <div dangerouslySetInnerHTML={this.createMarkup(this.state.articles[2])} /> : null }
          </footer>
        </div>
      </div>
    )
  }
}

export default App