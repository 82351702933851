import React from 'react'
import Picbutton from './PicButton'
import * as Popmessages from '../Components/Popmessages'
import '../css/alert.css'

const Alert = (props) => {
    
    let { message, poptype, closePopup } = props
    let pmessage = message;
    if (poptype === "loading" ) pmessage = "" 
    if (poptype === "saving" ) pmessage = "Saving..." 

    return (
        <div className={ poptype + " popup"}>
            {props.header ? props.header : null}
            <div className="darken"></div>
            <div className="tfBig popupMessage">{ pmessage }</div>
            { poptype ==="loading" || poptype ==="saving" || poptype==="populating" ? <div className="lds-dual-ring"></div> : null }
            { poptype === "answer" ? <div><Picbutton btID="alertyes" onFormSubmit={closePopup} btClassName="bgRed fgWhite " btIcon="\uf00c" btLabel={Popmessages.btnYes} /><Picbutton btID="alertno" onFormSubmit={closePopup} btClassName="bgWhite fgBlack " btIcon="\uf00d" btLabel={Popmessages.btnNo} /></div> : null }
            { poptype !=="loading" && poptype !=="saving" && poptype !== "answer" ? <Picbutton onFormSubmit={closePopup} btClassName={`${poptype === 'error' ? 'bgRed' : 'bgGreen'} fgWhite rounded`} btIcon="\uf00d" /> : null }
        </div>
    )
}

Alert.defaultProps = {
    poptype: 'alert',
    message: 'This is an Alertbox!'
}

export default Alert;