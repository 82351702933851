import {fromJS} from 'immutable';
import MAP_STYLE from '../Datasource/lightstyle.json'

const mapStyle = {
    ...MAP_STYLE,
    sources: {...MAP_STYLE.sources},
    layers: MAP_STYLE.layers.slice()
}

mapStyle.layers.splice(
  mapStyle.layers.findIndex(layer => layer.id === 'cf-countries-all'),
  0,
  {
    id: 'cf-counties-highlighted',
    type: 'fill',
    source: "composite",
    "source-layer": "ne_50m_admin_0_countries-3od7un",
    paint: {
      'fill-outline-color': '#484896',
      'fill-color': '#308BCC',
      'fill-opacity': 0.5
    },
    filter: ['==', ['get', 'ISO_A3'], '']
  }
);
  

export const highlightLayerIndex = mapStyle.layers.findIndex(
    layer => layer.id === 'cf-counties-highlighted'
);

export const defaultMapStyle = fromJS(mapStyle);