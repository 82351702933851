import React, {Component} from 'react';
import MapGL from 'react-map-gl';
import WebMercatorViewport from 'viewport-mercator-project';
import {defaultMapStyle, highlightLayerIndex} from './Map-style.js';
import Chart from "react-apexcharts";
import '../css/Pageanim.css'
import noavatar from '../images/no-avatar.png'

const mapboxToken = 'pk.eyJ1IjoicGhvZWJpYSIsImEiOiJjazB0bzFxd3AwZTR6M25wZTN1dXQwM29lIn0.83vRbAsBAY5rgbiA6TYpXg'

function getCountryData(data,iso) {
  const countyData = data.map((datas,dataindex) => {
    return datas[1].filter(country => country.countryiso3code === iso)
  })
  return countyData
}

function fixnum(numb) {
  if (numb > 1000000000) return numb/1000000000
}

class Map extends Component {
  constructor(props) {
    super(props)
    this.state = {
      height: 0,
      width: 0,
      popstate: 'closed',
      mapstyle: defaultMapStyle,
      feature: null,
      selCountry: '',
      viewport: {
        width: '100%',
        height: '100%',
        latitude: 48.640191,
        longitude: 19.301821,
        zoom: 4.5,
        bearing: 0,
        pitch: 0,
        scrollZoom: false
      },
    }
    this.glmapref = React.createRef()
    this.contactContent = []
    this.macroContent = []
    this.charts = null
  }
  
  _handleUpdateViewport = viewport => {
    this.setState({viewport})
  }

  handleOnClick = (event) => {
    const feature = event.features[0];
    const { countries, contacts } = this.props
    if (feature) {
      const iso = feature.properties.ADM0_A3
      this.macroContent = countries.find(obj => obj.iso === iso )
      this.contactContent = contacts.filter(obj => { 
        let cisoarr = obj.ciso.replace(/ /g,'').split(',')
        return cisoarr.find(cisoarritem => cisoarritem === iso)  } 
      )
      
      /***** Compute CHARTs *****/
      let chartrender = getCountryData(this.props.data, iso)
      if ( chartrender ) {
        this.charts = chartrender.map((dataelem, i) => {
          let ishuge = false
          dataelem.sort((a, b) => (a.date > b.date) ? 1 : -1 )
          let copt = {chart: { id: i }, xaxis: { categories: dataelem.map(data => data.date)} }
          let cser = [{name: dataelem[0].indicator['value'], data: dataelem.map(data => {
              if ((data['value'] > 1000000000 || ishuge === true) && data['value'] !== null) {
                ishuge = true
                return fixnum(data['value']).toFixed(2)
              } else {
                if (data['value'] !== null) {
                  return data['value'].toFixed(2)
                } else {
                  return 0
                }
              }
            })
          }]
          return (
            <div className="chart" key={i}>
              <h3>{dataelem[0].indicator['value']} {ishuge === true ? '(Billion)' : ''}</h3>
              <Chart
                options={copt}
                series={cser}
                type={this.props.settings[0].chart_type[0]}
                width='100%'
                height='300px' />
            </div>
          )
        })
      }
      this.setState({
        mapstyle: defaultMapStyle.setIn(['layers', highlightLayerIndex, 'filter', 2], feature.properties.ADM0_A3), feature: feature, popstate: this.state.popstate === 'closed' ? 'opened' : 'opened', selCountry: iso
      })
    }
  }

  componentDidMount = () => {
    let st 
    if (this.props.settings) {
      st = this.props.settings[0]
    }
    let minLng = 5
    let minLat = 40.5
    let maxLng = 30
    let maxLat = 55
    if (st) {
      minLng = parseFloat(st.minlng)
      minLat = parseFloat(st.minlat)
      maxLng = parseFloat(st.maxlng)
      maxLat = parseFloat(st.maxlat)
    }
    const height = this.divElement.clientHeight;
    const width = this.divElement.clientWidth;
    const stv = this.state.viewport
    // construct a viewport instance from the current state
    const viewport = new WebMercatorViewport({ width: width, height: height, zoom: stv.zoom, latitude: stv.latitude, longitude: stv.longitude, bearing: 0, pitch: 0, scrollZoom: false });
    const {longitude, latitude, zoom} = viewport.fitBounds([[minLng, minLat], [maxLng, maxLat]], {
      padding: 0
    })

    this.setState({
      viewport: {
        ...this.state.viewport,
        longitude,
        latitude,
        zoom
      }
    })
  }
  
  render = () => {
    const rendercontact = this.contactContent.map((item, i) => {
      return(
        <div className="contact" key={i}>
          <figure>
            <div className='imgwrap'>
              <img src={item.picurl ? item.picurl : noavatar} alt={item.cname} />
            </div>
          </figure>
          <ul>
            <li className='cname'>{item.cname}</li>
            <li className='jtitle'>{item.jtitle}</li>
            <li className='phone'>{item.phone}</li>
            <li className='social'><a href={`tel:${item.phone.replace(/ /g, '')}`}><span className="icon-phone"></span></a><a href={`mailto:${item.email}`}><span className='icon-envelop' title={item.email}></span></a><a href={item.linkedin} target="_blank" rel="noopener noreferrer"><span className='icon-linkedin2' title={item.linkedin}></span></a></li>
          </ul>
        </div>
      )
    })

    return (
        <div className="mapholder" ref={ (divElement) => this.divElement = divElement }>
          <MapGL
              ref={this.glmapref}
              mapboxApiAccessToken={mapboxToken}
              mapStyle={this.state.mapstyle}
              {...this.state.viewport}
              interactiveLayerIds={['cf-countries-all']}
              onViewportChange={this._handleUpdateViewport}
              onClick={this.handleOnClick}
              />
          <div className="transparent scrollenabler"></div>
          <div className={`poppanel ${this.state.popstate}`}>
              { this.state.popstate === 'closed' ? <p className='small'>Click on the countries for further information.</p> : null } 
              <div className='contacts'>
                <h3>Contact</h3>
                {rendercontact}
              </div>
              <div className={`macrodata ${ this.contactContent.length > 1 ? 'h2' : this.contactContent.length > 2 ? 'h2' : ''}`}>
                {this.charts}
                <p className="datasetby smaller">Datasets by World Bank.</p>
              </div>
          </div>
        </div>
    )
  }
}

export default Map